import React, { FC } from 'react'
import { Layout } from '../old-components'
import { graphql, PageProps } from 'gatsby'
import { ILandingPageQuery } from '../types/landing-page'
import { Sections } from '../components/sections/sections'

type FAQsPageProps = PageProps<ILandingPageQuery>

const FAQPage: FC<FAQsPageProps> = ({ data }) => {
  const { contentfulLandingPage } = data
  return (
    <Layout title={contentfulLandingPage.pageTitle}>
      <Sections sections={contentfulLandingPage.sections} />
    </Layout>
  )
}

export const query = graphql`
  query FAQsPageQuery($language: String) {
    contentfulLandingPage(
      slug: { eq: "faqs" }
      node_locale: { eq: $language }
    ) {
      internalName
      pageTitle
      sections {
        __typename
        ... on ContentfulSectionText {
          contentful_id
          id
          title
          text {
            raw
          }
        }
      }
    }
  }
`

export default FAQPage
